.page > .assist {
  @extend .container;

  textarea {
    min-height: 15em;
  }

  .tags {
    .btn {
      margin: 0 $btn-padding-x-sm;
    }
  }

  .submit {
    text-align: right;
  }
}
