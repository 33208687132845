.visio {
  @extend .container;

  .screen {
    @extend .row;

    background-image: url('../assets/images/background-desk.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    padding-bottom: 56.3%;

    & > div {
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: transparentize($dark, 0.3);
      color: $light;
      border-bottom: solid $primary 0.35em;
    }

    .will-start,
    .is-closed,
    .loading {
      text-align: center;

      color: $light;
      font-size: 1.5em;

      .icon {
        font-size: 4em;
      }
    }

    .loading {
      .icon > svg {
        animation: rotation 2s infinite linear;
      }
    }
  }

  .controls {
    @extend .row;
    @extend .mt-2;
    @extend .mb-5;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .group {
      button {
        @extend .mx-1;
        font-size: 1.5em;
        height: 2em;
        width: 2em;
        padding: 0 0 0.1em 0;
        border-radius: 1em;
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
