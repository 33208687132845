.modal-content > .response {
  textarea {
    min-height: 10em;
  }

  .submit {
    text-align: right;
    button {
      margin-left: 1rem;
    }
  }
}
