.home {
  .video {
    position: relative;
    padding-bottom: 56.3%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
