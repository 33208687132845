.tags {
  .tag-list {
    padding: $input-btn-padding-y 0;
    min-height: $input-btn-padding-y * 2 + 2rem;

    button {
      margin: 0 $btn-padding-x-sm;
    }
  }
}
