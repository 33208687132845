.page > .register {
  background-image: url('../assets/images/background-code.jpg');
  background-size: cover;
  background-position: center;

  height: 100%;
  .mask {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    background-color: transparentize($dark, 0.5);

    .center {
      @extend .container;
      width: 30em;
      height: auto;

      .logo {
        @extend .row;
        @extend .justify-content-center;
        font-size: 3em;
      }

      .form {
        border-top: $primary solid 0.25em;
        background-color: $dark;
        box-shadow: transparentize($primary, 0.8) 0em 0.5em 3em;
        color: $light;

        .title {
          font-weight: $font-weight-light;
          padding: 0.5em 0;
        }

        .form-group {
          .form-control {
            color: $light;
          }
        }
      }
    }
  }
}
