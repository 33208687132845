html,
body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100vh;
  background-color: $light;
}

#root {
  height: 100%;
}

.layout {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: stretch;
  flex-grow: 1;

  .page > .container {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 1em;
    }
  }

  .page > .container,
  .modal-dialog > .container {
    min-height: 100%;
    padding-top: 1rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1rem;
      color: $dark;

      &.row {
        align-items: flex-end;

        .title-icon {
          color: $primary;
          text-align: right;
        }
      }
    }
    h1 {
      font-weight: $font-weight-light;
      text-transform: uppercase;
      border-bottom: 0.1rem solid $primary;
    }
    h2 {
      font-weight: $font-weight-normal;
      font-size: 1.5rem;
      border-bottom: 1px solid $gray-400;
    }
    h3 {
      margin-top: 1em;
      font-weight: $font-weight-normal;
      font-size: 1.2rem;
    }
  }
}

@import './layout/header';
@import './layout/page';
@import './layout/modal';
@import './layout/toasts';
@import './layout/tooltip';
@import './layout/footer';
