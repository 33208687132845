$border-width: 0.3em;
$border: $border-width solid $primary;
$size: 20em;
$padding: 0.5em;
$arrow: 1em;

.tooltip {
  position: fixed;
  color: $light;
  background-color: $dark;
  padding: $padding;

  max-height: 50%;
  max-width: 75%;

  &.top,
  &.bottom {
    width: $size;
    margin: 0 $size/-2;
  }

  &::after,
  &::before {
    display: block;
    border: $arrow solid transparent;
    position: absolute;
    width: 0;
    margin-left: $size/2 - $arrow - $padding;
  }

  &.top {
    border-bottom: $border;
    margin-bottom: $arrow;

    &::after {
      border-top-color: $primary;
      margin-top: $padding + $border-width;
      content: '';
    }
  }
  &.bottom {
    border-top: $border;
    margin-top: $arrow;
    &::before {
      border-bottom-color: $primary;
      margin-top: -$padding - 2em - $border-width;
      content: '';
    }
  }
}
