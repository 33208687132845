.page > .interventions,
.page > .availabilities {
  @extend .container;

  display: flex;
  flex-flow: column nowrap;

  .row {
    flex-grow: 0;

    &.grid {
      flex-grow: 1;
    }
  }

  .event {
    background-color: $dark;
    border-color: $secondary;
    padding: 0.2em;
    color: $light;

    &.intervention {
      background-color: $secondary;
      border-color: $dark;
    }
  }
}
