.score {
  a {
    display: inline-block;
    margin: 0.2em;

    &.full {
      color: $primary;
    }
    &.empty {
      color: $white;
    }
  }
}
