.page > .login {
  background-image: url('../assets/images/background-sunset.jpg');
  background-size: cover;
  background-position: center;

  height: 100%;
  .mask {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    background-color: transparentize($dark, 0.5);

    .center {
      @extend .container;
      position: relative;
      width: 20em;
      height: auto;

      .return {
        position: absolute;
        top: 1.5em;
      }

      .logo {
        @extend .row;
        @extend .justify-content-center;
        font-size: 3em;
      }

      .form {
        @extend .row;
        @extend .justify-content-center;

        border-top: $primary solid 0.25em;
        background-color: $dark;

        box-shadow: transparentize($primary, 0.8) 0em 0.5em 3em;

        .title {
          color: $light;
          font-weight: $font-weight-light;
          padding: 0.5em 0;
        }

        .form-group {
          @extend .col-12;

          .input-group {
            .form-control,
            .input-group-text {
              border-color: $light;
              color: $light;
            }
          }

          .form-text.text-muted {
            text-align: right;
            font-size: 0.75em;
          }
          &.submit {
            text-align: center;
          }
        }
      }
    }
  }
}
