table.table {
  thead {
    tr {
      th {
        border-top: 0;

        &.w-15 {
          width: 15%;
        }
        &.w-20 {
          width: 20%;
        }
        &.w-25 {
          width: 25%;
        }
        &.w-30 {
          width: 30%;
        }
        &.w-35 {
          width: 35%;
        }
        &.w-40 {
          width: 40%;
        }
      }
    }
  }
}
