@keyframes loader {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100vw 0;
  }
}

.header {
  .content {
    order: 1;
    height: 4em;
    background-color: $dark;
    color: $light;
    z-index: 10000;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .logo {
      padding-left: 1rem;
      font-size: 2em;
    }

    .nav {
      .nav-link {
        color: $light;
        cursor: pointer;

        &.active {
          color: $primary;
        }
      }

      a.dropdown-toggle {
        color: $light;
      }
      a.dropdown-toggle.active {
        color: $primary !important;
      }
    }
  }

  .progress {
    height: 0.5em;
    div.progress-bar {
      width: 100%;

      &.active {
        @extend .progress-bar-animated;
        background-image: linear-gradient(
          90deg,
          transparent 45%,
          opacify($white, 0.8) 50%,
          transparent 55%,
          transparent
        );
        animation: loader 0.8s ease-in-out infinite;
      }
    }
  }
}
