@import './bootstrap-override';
@import '../node_modules/bootstrap-scss/bootstrap.scss';

@import './font';

@import './commons';

@import './components';
@import './layout';
@import './pages';

* {
  transition: background-color 0.3s;
}
