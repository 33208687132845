.toasts {
  position: fixed;
  bottom: 2em;
  height: 0;

  & > .row {
    width: 100%;

    & > .container {
      @extend .col-12;
      @extend .col-sm-12;
      @extend .offset-md-2;
      @extend .col-md-8;
      @extend .offset-lg-3;
      @extend .col-lg-6;
      @extend .offset-xl-4;
      @extend .col-xl-4;

      position: fixed;
      bottom: 2em;
      .alert {
        @extend .col-12;
        z-index: 2000;
      }
    }
  }
}
