.footer {
  order: 3;
  height: 2em;
  padding: 0.2em;
  background-color: $dark;
  border-top: $primary 0.1em solid;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: $light;
  z-index: 10000;

  .copyright {
    font-size: 0.8em;
  }

  .by {
    font-size: 0.8em;

    a {
      color: $primary;
    }

    span {
      font-size: 0.8em;
    }
  }
}
