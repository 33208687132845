.logo {
  &.logo-text {
    text-align: center;
    span:nth-child(1) {
      color: $light;
      font-weight: $font-weight-bold;
    }
    span:nth-child(2) {
      color: $primary;
      font-weight: $font-weight-light;
    }
  }
}
