.profile-menu {
  padding: 1rem;
  height: 100%;
  .user-name {
    display: inline-block;
    vertical-align: middle;
    line-height: 0.85em;
    text-align: right;
    // text-transform: uppercase;

    .user-firstname {
      color: $light;
      font-weight: $font-weight-bold;
    }
    .user-lastname {
      color: $primary;
      font-weight: $font-weight-light;
    }
  }

  .caret {
    margin-left: 0.5em;
    &::after {
      vertical-align: middle;
    }
  }
}
