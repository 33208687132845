.page > .profile {
  @extend .container;

  .personnal-information {
    @extend .row;
  }

  .submit {
    text-align: right;
  }
}
