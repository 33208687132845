.modal {
  display: block;
  &.fade {
    background-color: transparentize($dark, 0.5);
  }

  .modal-dialog {
    min-width: 50%;

    .cross {
      cursor: pointer;
      pointer-events: auto;
      z-index: 2000;
      display: block;
      @extend .rounded-circle;
      background-color: $white;
      color: $dark;
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      position: absolute;
      right: -1.5rem;
      top: -1.5rem;
      border: solid 0.25rem $white;
      svg {
        vertical-align: top;
      }

      &:hover {
        color: $primary;
      }
    }

    .scrollable {
      max-height: calc(100vh - 15rem);
      overflow-y: auto;
    }
  }
}
